<template>
    <div class="hero">
        <div class="hero-body px-0 py-2 bg-cyan-50">
            <div class="is-flex is-align-items-center py-3">
                <span @click="useHrStore().toggle_section_panels('employee')" 
                    class="w-1/5 font-semibold mr-auto pl-2 is-clickable">
                    <span class="mr-2">
                        <i v-if="useHrStore().employee_section_data.show_section" 
                            class="fa-solid fa-chevron-up"></i>
                        <i v-else class="fa-solid fa-chevron-down"></i>
                    </span>
                    PUNONJES
                </span>
                <div class="is-flex-grow-1 tile-grid-container">
                    <home-tile v-for="tile in tiles_data" :key="tile"
                        :title="tile.title"
                        :icon="tile.icon ? tile.icon : ''"
                        :dropdown_list="tile.dropdown_list"
                        :background="tile.background"
                        :sel_user="useHrStore().employee_section_data.selected_user"
                    ></home-tile>
                </div>
            </div>
            <div v-if="useHrStore().employee_section_data.is_loading">

            </div>
            <div v-if="useHrStore().employee_section_data.show_section" class="is-flex w-full mt-4">
                <div class="w-1/3 p-2">
                    <hr-section-panel
                        :title="'Punonjes'"
                        :entity="'employee'"
                        :entity_list="useHrStore().filtered_employees_list"
                        :category_list="useHrStore().employee_section_data.employee_types"
                        :footer_btns="[
                            {label: 'Te gjithe punonjesit', link: '#', class:'is-link is-outlined'}
                        ]"
                    ></hr-section-panel>
                </div>
                 <div class="w-1/3 p-2">
                    <hr-section-panel
                        :title="'Kerkesa'"
                        :entity="'requests'"
                        :entity_table_data="{
                            'aabbcc': {name: 'Name 1', status: 1, kategoria: 4 }
                        }"
                        :footer_btns="[
                            {label: 'Te gjithe kerkesat', link: '#', class:'is-link is-outlined'},
                        ]"
                    ></hr-section-panel>
                </div>
                <!--<div class="w-1/4 p-2">
                </div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useHrStore } from "@/stores/hr";
    import { provide } from 'vue';
    provide('employee_list', useHrStore().employee_list);
</script>

<script>

import homeTile from "./home_tile.vue";
import hrSectionPanel from "./hr_section_panel.vue";
    export default {
        components: { homeTile, hrSectionPanel  },
        setup() {
        },
        data() {
            return {
                show_section_panels: false,
                tiles_data: [
                    {
                        title: '',
                        icon: 'fa-solid fa-user-tie',
                        dropdown_list: [
                            {label: 'Lista e punonjesve', link: '#'},
                            {label: 'Kategorite e punonjesve', link: '#'},
                        ],
                        background: 'bg-cyan-100'
                    },
                    {
                        title: 'Pjesëmarrja në punë',
                        dropdown_list: [
                            {label: 'Label 1', link: '#'},
                            {label: 'Label 2', link: '#'},
                            {label: 'hline'},
                            {label: 'Label 3', link: '#'},
                        ],
                        background: 'bg-cyan-200'
                    },
                    {
                        title: 'Kerkesa per leje',
                        dropdown_list: [
                            {label: 'Leje Vjetore', link: '#', krudh: true, krudh_entity: 'hr_employee_leave'},
                            {label: 'Leje ditore', link: '#'},
                            {label: 'Leje leje pa te drejte page', link: '#'},
                        ],
                        background: 'bg-cyan-300'
                    },
                    {
                        title: 'Kalendari i turneve',
                        dropdown_list: [
                            {label: 'Kalendari mujor', link: '#'},
                            {label: 'hline'},
                            {label: 'Kategorite e turneve', link: '#'},
                        ],
                        background: 'bg-cyan-400'
                    }
                ],
                // sections_data
            }
        },
        methods: {
            // toggle_section_panels(){
            //     // this.show_section_panels = !this.show_section_panels
            //     useHrStore().show_section = !useHrStore().show_section

            //     if(useHrStore().show_section ){
            //         // Reset store data and children
            //         // useHrStore().employee_section_data = {}

            //         // Get employee section data
            //         var data = useHrStore().get_employee_section_data()

            //         console.log('d', data)
                    
            //     }
            // }
        },
        
    }
</script>

<style>


</style>