<template>
    <div>
        <nav class="panel bg-white">
            <div class="panel-heading bg-white is-flex is-align-items-center border-b" style="font-size:16px!important;">
                <span class="mr-auto" >
                    <p>
                        {{title}}
                    </p>
                    <small v-if="category_list && category_list.length > 0" class="text-xs font-thin text-slate-300">
                        Kategoria: Te gjitha
                    </small>
                </span>
                <p v-if="show_user_search" class="control has-icons-left">
                    <input v-model="search_entity_value" class="input is-small" type="text" placeholder="Kerko">
                    <span class="icon is-left">
                        <i class="fas fa-search" aria-hidden="true"></i>
                    </span>
                </p>
                <a @click.prevent="toggle_user_search()" href=""
                    class="mx-2">
                    <i  class="fa-solid fa-magnifying-glass"></i>
                </a>

                <div v-if="category_list && category_list.length > 0" class="dropdown is-right is-active">
                    <div class="dropdown-trigger">
                        <button @click.prevent="toggle_user_categories()" class="" aria-haspopup="true" aria-controls="dropdown-menu6">
                            <span><i  class="fa-solid fa-list"></i></span>
                            <!-- <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span> -->
                        </button>
                    </div>
                    <div v-if="show_user_categories" class="dropdown-menu"
                        style="min-width:250px;"
                        id="dropdown-menu6" role="menu">
                        <div class="dropdown-content">
                            <div class="dropdown-item">
                                <small class="font-thin">Kategori</small>
                                <p class="control has-icons-left">
                                <input v-model="search_val" class="input is-small" type="text" placeholder="Kerko Kategori Perdoruesi">
                                <span class="icon is-left">
                                    <i class="fas fa-search" aria-hidden="true"></i>
                                </span>
                            </p>
                            </div>
                            <hr class="dropdown-divider">

                            <a @click.prevent="useHrStore().select_category(entity, {})" class="dropdown-item">Te gjitha</a>

                            <a v-for="cat in filtered_category_list" :key="cat"
                                @click.prevent="useHrStore().select_category(entity, cat)"
                                class="dropdown-item">
                                {{cat.name}}
                            </a>
                            <!-- <a href="#" class="dropdown-item is-active">
                                Cat 3
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table block -->
            <div v-if="entity_table_data" class="panel-block">

                <!-- <pre> {{Object.keys(entity_table_data)}} </pre> -->

                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Nga</th>
                            <th>Kerkuar me</th>
                            <th>Tipi</th>
                            <th class="has-text-right">Statusi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Herbert Lilo</td>
                            <td>25/6/2022</td>
                            <td>Kerkese per leje vjetore</td>
                            <td class="has-text-right">
                                <span class="fa-stack fa-xs">
                                    <i class="fas fa-circle fa-stack-2x text-amber-300"></i>
                                    <i class="fas fa-hourglass fa-stack-1x fa-inverse"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Herbert Lilo</td>
                            <td>25/6/2022</td>
                            <td>Kerkese per leje vjetore</td>
                            <td class="has-text-right">
                                <span class="fa-stack fa-xs">
                                    <i class="fas fa-circle fa-stack-2x text-red-300"></i>
                                    <i class="fas fa-xmark fa-stack-1x fa-inverse"></i>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Herbert Lilo</td>
                            <td>25/6/2022</td>
                            <td>Kerkese per leje vjetore</td>
                            <td class="has-text-right">
                                <span class="fa-stack fa-xs">
                                    <i class="fas fa-circle fa-stack-2x text-green-300"></i>
                                    <i class="fas fa-check fa-stack-1x fa-inverse"></i>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <!-- BLOCK: HR Home - Users list -->
            <div v-if="filtered_entity_value" class="border-b">
                <a v-for="a in Object.keys(filtered_entity_value)" :key="a" class="panel-block is-align-items-stretch py-0">
                    <span class="panel-icon is-align-self-center w-10 is-flex is-flex-direction-column is-justify-content-center">
                        <i class="fa-solid fa-user-tie text-indigo-400"></i>
                        <span class="text-xs text-slate-400">
                            {{filtered_entity_value[a].type_name
                            }}
                        </span>
                    </span>
                    <div class="is-flex is-flex-direction-column is-align-self-center">
                        <span> {{filtered_entity_value[a].user_name}} </span>
                        <span class="text-sm text-slate-400"> {{filtered_entity_value[a].user_email}} </span>
                    </div>
                    <div class="border-l w-1/4 ml-auto is-flex is-flex-direction-column is-justify-content-center is-align-center pl-1">
                        <span v-for="pos in filtered_entity_value[a].pos" :key="pos"
                            class="text-xs text-slate-400">
                            {{pos}}
                        </span>
                    </div>
                </a>
            </div>

            <p v-if="entity_list" class="panel-block is-flex is-justify-content-center">
                <nav class="pagination is-small is-centered" role="navigation" aria-label="pagination">
                    <a class="pagination-previous"><i class="fa-solid fa-chevron-left"></i></a>
                    <a class="pagination-next"><i class="fa-solid fa-chevron-right"></i></a>
                    <ul class="pagination-list">
                        <li><a class="pagination-link is-current" aria-label="Goto page 1">1</a></li>
                        
                        <li><a class="pagination-link" aria-label="Goto page 2">2</a></li>
                    </ul>
                </nav>
            </p>

            <div class="panel-block is-flex-direction-column">
                <button v-for="btn in footer_btns" :key="btn" :class="btn.class" :href="btn.link" class="button is-small is-fullwidth mb-1">
                    {{btn.label}}
                </button>
            </div>
        </nav>
    </div>
</template>

<script setup>
    import { useHrStore } from "@/stores/hr";
    // import { inject } from 'vue';
    // const employee_list = inject('employee_list');

</script>

<script>

    export default {
        props: ['title', 'entity', 'entity_list', 'category_list', 'entity_table_data', 'footer_btns'],
        data() {
            return {
                show_user_search: false,
                show_user_categories: false,
                search_val: '',
                search_entity_value: ''
            }
        },
        methods: {
            toggle_user_search(){
                this.show_user_search = !this.show_user_search
            },
            toggle_user_categories(){
                this.show_user_categories = !this.show_user_categories
            }
        },
        computed: {
            filtered_category_list(){
                if (this.search_val === '') {
                    return this.category_list;
                }
                const searchRegex = new RegExp(this.search_val, 'i');
                return this.category_list.filter(item => searchRegex.test(JSON.stringify(item)));
            },
            filtered_entity_value(){
                if (this.search_entity_value === '') {
                    return this.entity_list;
                }
                const searchRegex = new RegExp(this.search_entity_value, 'i');
                return Object.values(this.entity_list).filter(item => searchRegex.test(JSON.stringify(item)));
            }
            

        },

    }
</script>

<style lang="scss" scoped>

</style>