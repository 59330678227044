import { defineStore } from 'pinia'
import Api from "@/services/Api";

export const useHrStore = defineStore({
  id: 'hr',
  state: () => ({
    employee_section_data:{
        show_section: false,
        is_loading: false,
        employee_list: {},
        employee_types: [],
        selected_user_type: {},
        selected_user: {}
    },
  }),
  getters: {
    filtered_employees_list(){
        if(this.employee_section_data.selected_user_type.id){
            var filtered = Object.keys(this.employee_section_data.employee_list).filter(k => {
                return this.employee_section_data.employee_list[k].user_type_id == this.employee_section_data.selected_user_type.id
            })
            // Create a new object to store the filtered items
            const filteredList = {};
            // Iterate over the keys of the employee_list object
            for (const key in this.employee_section_data.employee_list) {
                // Check if the ID is present in the filtered array
                if (filtered.find(f => f == this.employee_section_data.employee_list[key].user_id)) {
                    // If yes, add the item to the filteredList object
                    filteredList[key] = this.employee_section_data.employee_list[key];
                }
            }
            // Return the filteredList
            return filteredList;
        } 
        else {
            return this.employee_section_data.employee_list
        }
    }
  }, 
  actions: {
    toggle_section_panels(entity){
        // console.log('here?', entity, this.employee_section_data.show_section)
        if(entity == 'employee'){
            this.employee_section_data.show_section = !this.employee_section_data.show_section
                if(this.employee_section_data.show_section ){
                // Reset store data and children
                this.employee_section_data.employee_list = {},
                this.get_employee_section_data()
            }
        }
    },
    async get_employee_section_data(){
        this.employee_section_data.is_loading = true
        await Api(true).post("hr/get/employee/section/data", {})
        .then(res => {
            
            // Employee list
            res.data.users.forEach(u => {
                if(!this.employee_section_data.employee_list[u.user_id]) this.employee_section_data.employee_list[u.user_id] = {}
                this.employee_section_data.employee_list[u.user_id].user_id = u.user_id
                this.employee_section_data.employee_list[u.user_id].user_name = u.user_name
                this.employee_section_data.employee_list[u.user_id].user_email = u.user_email
                this.employee_section_data.employee_list[u.user_id].user_type_id = u.user_type_id
                this.employee_section_data.employee_list[u.user_id].type_name = u.type_name
                if(!this.employee_section_data.employee_list[u.user_id].pos) this.employee_section_data.employee_list[u.user_id].pos = []
                this.employee_section_data.employee_list[u.user_id].pos.push(u.pos_name)
            });
            
            // User types list
            this.employee_section_data.employee_types = res.data.user_types
            this.employee_section_data.selected_user = res.data.selected_user
        })
        this.employee_section_data.is_loading = false
    },
    select_category(entity, category){
        if(entity == 'employee'){
            this.employee_section_data.selected_user_type = category
        }
    },
    get_user() {

    }
  }
})

