<template>
    <section class="hero">
        <div class="hero-body px-0 py-2 bg-teal-50">
            <div class="is-flex is-align-items-center py-3">
                <span @click="toggle_section_panels()" class="w-1/5 font-semibold mr-auto pl-2 is-clickable">
                    <span class="mr-2">
                        <i v-if="show_section_panels" class="fa-solid fa-chevron-up"></i>
                        <i v-else class="fa-solid fa-chevron-down"></i>
                    </span>
                    PAGAT
                </span>
                <div class="is-flex-grow-1 tile-grid-container">
                    <home-tile
                        :title="''"
                        :icon="'fa-solid fa-hand-holding-dollar'"
                        :nr_count="75"
                        :dropdown_list="[
                            {label: 'Lista e pagave per punonjesit', link: '#'},
                            {label: 'hline'},
                            {label: 'Kategori pagash', link: '#'},
                        ]"
                        :background="'bg-teal-100'"
                    ></home-tile>
                    <home-tile
                        :title="'Taksat'"
                        :dropdown_list="[
                            {label: 'Link 1', link: '#'},
                            {label: 'hline'},
                            {label: 'Link 2', link: '#'},
                        ]"
                        :background="'bg-teal-200'"
                    ></home-tile>
                    <home-tile
                        :title="'Bonuse'"
                        :dropdown_list="[
                            {label: 'Link 1', link: '#'},
                            {label: 'hline'},
                            {label: 'Link 2', link: '#'},
                        ]"
                        :background="'bg-teal-300'"
                    ></home-tile>
                    <home-tile
                        :title="'Sigurime shoqerore'"
                        :dropdown_list="[
                            {label: 'Link 1', link: '#'},
                            {label: 'hline'},
                            {label: 'Link 2', link: '#'},
                        ]"
                        :background="'bg-teal-400'"
                    ></home-tile>
                </div>
            </div>
            <div v-if="show_section_panels" class="is-flex w-full mt-4">
                <div class="w-1/4 p-2">
                    <home-user-panel></home-user-panel>
                </div>
                <div class="w-1/2 p-2">
                    <home-user-panel></home-user-panel>
                </div>
                <div class="w-1/4 p-2">
                    <home-user-panel></home-user-panel>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import homeTile from "./home_tile.vue";
import homeUserPanel from "./hr_section_panel.vue";
    export default {
        components: { homeTile, homeUserPanel },
        data() {
            return {
                show_section_panels: false
            }
        },
        methods: {
            toggle_section_panels(){
                this.show_section_panels = !this.show_section_panels
            }
        },
        
    }
</script>

<style>

</style>