<template>
        <div class="border tile-grid-item p-2 mr-3 relative"
            :class="background"
        >
            <div @click="toggle_dropdown()" class="subtitle mb-0 is-flex relative is-clickable">
                <span class=""> 
                    <span v-if="icon" class="mr-2"> <i :class="icon" class="fa-sm"></i> </span>
                    <span class="text-base"> {{title}} </span>
                </span>
                <span v-if="dropdown_list && dropdown_list.length > 0" class="ml-auto text-slate-400">
                    <i v-if="show_dropdown" class="fa-solid fa-chevron-up fa-xs"></i>
                    <i v-else class="fa-solid fa-chevron-down fa-xs"></i>
                </span>

            </div>
            <div v-if="show_dropdown" 
                class="dropdown-menu absolute left-0 bottom-0 block w-full" 
                style="display:block!important;" >
                <div  class="dropdown-content">
                    <a v-for="item in dropdown_list" :key="item" :href="item.link"
                        @click.prevent="open_dropdown_item(item)"
                        :class="{'dropdown-item' : item.label != 'hline'}">
                        <span v-if="item.label != 'hline'">
                            {{item.label}}
                        </span>
                        <hr v-if="item.label == 'hline'" class="dropdown-divider">
                    </a>
                </div>
            </div>
        </div>
</template>

<script setup>
    import { $vfm } from "vue-final-modal";
    import { defineAsyncComponent } from "vue"
</script>

<script>
    export default {
        props: ['title', 'icon', 'dropdown_list', 'background', 'sel_user'],
        data() {
            return {
                show_dropdown: false,
            }
        },
        methods: {
            toggle_dropdown(){
                this.show_dropdown = !this.show_dropdown
            },
            open_dropdown_item(i){
                // If is krudh
                if(i.krudh){
                    // console.log('open_krudh', i)
                    if(i.krudh_entity == 'hr_employee_leave'){
                        this.open_employee_leave_krudh()
                    }
                }
            },
            open_employee_leave_krudh(){
                var params = {
                    fields: [
                        'requested_by:current_user:Krijues:',
                        'type:Tipi i lejes',
                        'content: Pershkrimi',
                        'start_date: Fillon me',
                        'end_date: Mbaron me'
                        // 'icon:Ikona,*name:Emri', 
                    ],
                    entity: 'hr_employee_leave',
                    title: 'Kerkesa per leje',

                    join_select: [
                        'u.name requested_by_name',
                        'aa.name type_name'
                    ],
                    joins: [
                        {table: ' join users u', on: 'u.id = e.requested_by'},
                        {
                            table: " join ( select 1 as id, 'Normale' as name " +
                                "union all select 2 as id, 'Vecante' as name " +
                            ") aa ", on: " aa.id = e.type "
                        } 
                    ],

                    // columns: ['icon:80px', 'name:130px'],
                    // where: [
                    //     { field: 'process_id', operation: '=', value: entity.guid }
                    // ],
                    // defaults: [
                    //     {
                    //         field: 'process_id', value: entity.guid
                    //     }
                    // ]
                }
                $vfm.show({
                    component: defineAsyncComponent(
                        () => import("@/components/krudh-modal.vue")
                    ), params
                })
                console.log('user', this.sel_user)
            }
        },
        
    }
</script>

<style>
    .tile-grid-item {
        padding: 10px;
        text-align: center;
        }

</style>