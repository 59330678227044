<template>
    <section class="hero">
        <div class="hero-body px-0 py-2 bg-sky-50">
            <div class="is-flex is-align-items-center py-3">
                <span @click="toggle_section_panels()" class="w-1/5 font-semibold mr-auto pl-2 is-clickable">
                    <span class="mr-2">
                        <i v-if="show_section_panels" class="fa-solid fa-chevron-up"></i>
                        <i v-else class="fa-solid fa-chevron-down"></i>
                    </span>
                    REKLRUTIME
                </span>
                <div class="is-flex-grow-1 tile-grid-container">
                    <home-tile
                        :title="''"
                        :icon="'fa-solid fa-clipboard-user'"
                        :background="'bg-sky-100'"
                    ></home-tile>
                    <home-tile
                        :title="'Aplikime per pozicione pune'"
                        :background="'bg-sky-200'"
                    ></home-tile>
                    <home-tile
                        :title="'Onboarding and Offboarding'"
                        :background="'bg-sky-300'"
                    ></home-tile>
                </div>
            </div>
            <div v-if="show_section_panels" class="is-flex w-full mt-4">
                <div class="w-1/4 p-2">
                </div>
                <div class="w-1/2 p-2">
                </div>
                <div class="w-1/4 p-2">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import homeTile from "./home_tile.vue";
    export default {
        components: { homeTile },
        data() {
            return {
                show_section_panels: false
            }
        },
        methods: {
            toggle_section_panels(){
                this.show_section_panels = !this.show_section_panels
            }
        },
        
    }
</script>

<style>

</style>