<template>
    <div>
        <div class="is-flex bg-slate-200 px-2 py-1 title is-size-5 mb-3">
            HR management
        </div>

        <employee-home-section></employee-home-section>
        <payroll-home-section></payroll-home-section>
        <recruitment-home-section></recruitment-home-section>


    </div>
</template>

<script setup>
</script>

<script>


import employeeHomeSection from "./components/employee_home_section.vue";
import payrollHomeSection from "./components/payroll_home_section.vue";
import recruitmentHomeSection from "./components/recruitment_home_section.vue";
export default {
    components: { employeeHomeSection, payrollHomeSection, recruitmentHomeSection },
}
</script>

<style>

</style>